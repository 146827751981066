import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, catchError, map, tap, throwError } from "rxjs";

@Injectable()
export class RequestTooLargeInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status == 400) {
                    const errors = err.error.errors[''] as string[];
                    if (errors && (errors.filter(x => x.indexOf('Request body too large.') > 0 || x.indexOf('Multipart body length limit ') > 0))) {
                        err.requestTooLarge = true;
                    }
                }
                if (err.status == 413) {
                    err.requestTooLarge = true;
                }
                if (err.requestTooLarge) {
                    err.requestTooLargeMessage = 'Attachment is too large. The maximum total file size is 30MB.';
                }
                return throwError(() => err);
            })
        );
    }
}