import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  @Input() isHandset: boolean | null = false;

  private readonly _destroying$ = new Subject<void>();

  isAdmin: boolean = false;
  isReader: boolean = false;
  isInstructor: boolean = false;
  isReporter: boolean = false;
  isAllReportsReader: boolean = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.setMenuVisibility());
  }

  setMenuVisibility() {
    const account = this.authService.instance.getActiveAccount();

    this.isReporter =
      account?.idTokenClaims?.roles?.includes('Reporter') ?? false;
    this.isReader = account?.idTokenClaims?.roles?.includes('Reader') ?? false;
    this.isInstructor =
      account?.idTokenClaims?.roles?.includes('Instructor') ?? false;
    this.isAllReportsReader =
      account?.idTokenClaims?.roles?.includes('AllReportsReader') ?? false;
  }

  logout(): void {
    this.authService.logoutRedirect();
  }
}
