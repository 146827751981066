import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AccessForbiddenComponent } from './core/access-forbidden/access-forbidden.component';

const routes: Routes = [
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'report-form',
    loadChildren: () =>
      import('./report-form/report-form.module').then((m) => m.ReportFormModule)
  },
  {
    path: 'my-reports',
    loadChildren: () =>
    import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'access-forbidden',
    component: AccessForbiddenComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {canceledNavigationResolution: 'computed'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
