<mat-toolbar
  class="main-toolbar background-primary custom-toolbar-height"
  [class.mobile-toolbar]="isHandset$ | async">
  <button
    mat-icon-button
    aria-label="Toggle navigation menu"
    (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="container">
    <div class="container-logo">
      <img
        src="/assets/logoLot.svg?v=l2GzFO46aMwilrJV7CO0ELSpvsrUW1qrTKw6r3NoKSU"
        width="100"
        hspace="20"
        alt="PLL LOT" />
    </div>
    <div class="container-app-name">
      <span class="app-name">CABIN CREW REPORTS</span>
    </div>
    <div class="container-account-name">
      <span class="account-name">{{ accountName }}</span>
    </div>
    <div class="container-logout">
      <button mat-flat-button color="warn" (click)="logout()">
        <mat-icon
          aria-hidden="false"
          aria-label="Logout"
          fontIcon="logout"></mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </div>
  <div class="container-mobile">
    <div class="container-logo">
      <img
        src="/assets/logoLot.svg?v=l2GzFO46aMwilrJV7CO0ELSpvsrUW1qrTKw6r3NoKSU"
        width="100"
        hspace="20"
        alt="PLL LOT" />
    </div>
    <div class="container-center">
      <span class="app-name">CABIN CREW REPORTS</span>
      <span class="account-name">{{ accountName }}</span>
    </div>
    <div *ngIf="(isHandset$ | async) === false" class="container-logout">
      <button mat-flat-button color="warn" (click)="logout()">
        <mat-icon
          aria-hidden="false"
          aria-label="Logout"
          fontIcon="logout"></mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </div>
</mat-toolbar>
