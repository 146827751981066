<div class="main-container" [class.is-mobile]="isHandset$ | async">
  <app-header class="header" [sidenav]="sidenav"></app-header>

  <mat-sidenav-container
    class="main-sidenav-container"
    [style.marginTop.px]="(isHandset$ | async) ? 72 : 0">
    <mat-sidenav
      #sidenav
      autoFocus="false"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [fixedInViewport]="isHandset$ | async"
      fixedTopGap="56">
      <app-sidenav
        [sidenav]="sidenav"
        [isHandset]="isHandset$ | async"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content-container">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
